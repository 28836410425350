import bahchevieImageDesktop from './images/bahchevieDesktop.jpg';
import retinaBahchevieImageDesktop from './images/bahchevieDesktop@2x.jpg';
import bahchevieImageMobile from './images/bahchevieMobile.jpg';
import retinaBahchevieImageMobile from './images/bahchevieMobile@2x.jpg';

import citrusiImageDesktop from './images/citrusiDesktop.jpg';
import retinaCitrusiImageDesktop from './images/citrusiDesktop@2x.jpg';
import citrusiImageMobile from './images/citrusiMobile.jpg';
import retinaCitrusiImageMobile from './images/citrusiMobile@2x.jpg';

import corneplodiImageDesktop from './images/corneplodiDesktop.jpg';
import retinaCorneplodiImageDesktop from './images/corneplodiDesktop@2x.jpg';
import corneplodiImageMobile from './images/corneplodiMobile.jpg';
import retinaCorneplodiImageMobile from './images/corneplodiMobile@2x.jpg';

import fructiImageDesktop from './images/fructiDesktop.jpg';
import retinaFructiImageDesktop from './images/fructiDesktop@2x.jpg';
import fructiImageMobile from './images/fructiMobile.jpg';
import retinaFructiImageMobile from './images/fructiMobile@2x.jpg';

import ovoshiImageDesktop from './images/ovoshiDesktop.jpg';
import retinaOvoshiImageDesktop from './images/ovoshiDesktop@2x.jpg';
import ovoshiImageMobile from './images/ovoshiMobile.jpg';
import retinaOvoshiImageMobile from './images/ovoshiMobile@2x.jpg';

import yagodiImageDesktop from './images/yagodiDesktop.jpg';
import retinaYagodiImageDesktop from './images/yagodiDesktop@2x.jpg';
import yagodiImageMobile from './images/yagodiMobile.jpg';
import retinaYagodiImageMobile from './images/yagodiMobile@2x.jpg';

export type BannerType = {
  readonly title: string;
  readonly description: string;
  readonly normalImageDesktop: any;
  readonly retinaImageDesktop: any;
  readonly normalImageMobile: any;
  readonly retinaImageMobile: any;
};

export const bannersData: BannerType[] = [
  {
    title: 'Цитрусовые фрукты',
    description:
      'Мы рады предложить сочные и свежие цитрусовые фрукты, выращенные в южных странах, таких как Марокко, Перу или ЮАР.',
    normalImageDesktop: citrusiImageDesktop,
    retinaImageDesktop: retinaCitrusiImageDesktop,
    normalImageMobile: citrusiImageMobile,
    retinaImageMobile: retinaCitrusiImageMobile,
  },
  {
    title: 'Отборные свежие ягоды',
    description:
      'Мы предлагаем крупные и сладкие ягоды с изысканным ароматом, выращенные в экологически чистых условиях.',
    normalImageDesktop: yagodiImageDesktop,
    retinaImageDesktop: retinaYagodiImageDesktop,
    normalImageMobile: yagodiImageMobile,
    retinaImageMobile: retinaYagodiImageMobile,
  },
  {
    title: 'Бахчевые',
    description:
      'Арбузы и дыни со сладким и свежим ароматом. Их неповторимый вкус прекрасно освежает, тонизирует и утоляет жажду.',
    normalImageDesktop: bahchevieImageDesktop,
    retinaImageDesktop: retinaBahchevieImageDesktop,
    normalImageMobile: bahchevieImageMobile,
    retinaImageMobile: retinaBahchevieImageMobile,
  },
  {
    title: 'Свежие фрукты',
    description:
      'Лучшие вкусовые характеристики и прекрасный аромат. Наши фрукты выращены без использования пестицидов и вредных химических удобрений.',
    normalImageDesktop: fructiImageDesktop,
    retinaImageDesktop: retinaFructiImageDesktop,
    normalImageMobile: fructiImageMobile,
    retinaImageMobile: retinaFructiImageMobile,
  },
  {
    title: 'Фермерские овощи',
    description:
      'Мы работаем с проверенными фермерскими хозяйствами. Все овощи выращиваются с учётом особенностей каждого сорта. Они богаты витаминами и по-настоящему полезны.',
    normalImageDesktop: ovoshiImageDesktop,
    retinaImageDesktop: retinaOvoshiImageDesktop,
    normalImageMobile: ovoshiImageMobile,
    retinaImageMobile: retinaOvoshiImageMobile,
  },
  {
    title: 'Корнеплоды',
    description: 'Мы предлагаем широкий выбор питательных корнеплодов: чистые, целые, с аккуратной свежей ботвой.',
    normalImageDesktop: corneplodiImageDesktop,
    retinaImageDesktop: retinaCorneplodiImageDesktop,
    normalImageMobile: corneplodiImageMobile,
    retinaImageMobile: retinaCorneplodiImageMobile,
  },
];
