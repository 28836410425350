import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { BannerType } from 'data/banners';
import s from './Banner.module.css';

type Props = {
  readonly data: BannerType;
};

export const Banner = ({ data }: Props) => (
  <div className={s.root}>
    <picture>
      <source media="(max-width: 639px)" srcSet={`${data.normalImageMobile}, ${data.retinaImageMobile} 2x`} />
      <source media="(min-width: 639px)" srcSet={`${data.normalImageDesktop}, ${data.retinaImageDesktop} 2x`} />
      <img
        src={data.normalImageDesktop}
        srcSet={`${data.retinaImageDesktop} 2x`}
        alt={data.title}
        className={s.image}
      />
    </picture>
    <div className={s.content}>
      <div className={s.title}>{data.title}</div>
      <div className={s.description}>{data.description}</div>
      <Link to="/contacts" className={cn('button button-normal button-green', s.button)}>
        Связаться с нами
      </Link>
    </div>
  </div>
);
