/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import mobileImg from 'images/mobileSelectedFruits.jpg';
import desktopImg from 'images/desktopSelectedFruits.jpg';
import retinaDesktopImg from 'images/desktopSelectedFruits@2x.jpg';
import s from './SelectedFruits.module.css';

export const SelectedFruits = () => (
  <div className="py-16 mlg:py-12 mmd:py-10 msm:py-8">
    <div className="container flex items-center justify-between msm:block">
      <div className={s.left}>
        <div className={s.title}>Как отборные фрукты попадают к вам</div>
        <div className={s.description}>
          Благодаря многолетнему опыту сотрудничества с ведущими мировыми производителями и высочайшим стандартам
          контроля на всех этапах доставки и хранения, мы предлагаем самые качественные продукты.
        </div>
        <Link to="/delivery" className={cn('button button-normal button-yellow', s.desktopButton)}>
          Читать подробее о доставке
        </Link>
      </div>
      <picture>
        <source media="(max-width: 563px)" srcSet={`${mobileImg}, ${desktopImg} 2x`} />
        <source media="(min-width: 563px)" srcSet={`${desktopImg}, ${retinaDesktopImg} 2x`} />
        <img src={desktopImg} srcSet={`${retinaDesktopImg} 2x`} alt="" className={s.image} />
      </picture>
      <Link to="/delivery" className={cn('button button-normal button-yellow', s.mobileButton)}>
        Читать подробее о доставке
      </Link>
    </div>
  </div>
);
