import * as React from 'react';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Banners } from 'components/Banners';
import { Products } from 'components/Products';
import { Advantages } from 'components/Advantages';
import { SelectedFruits } from 'components/SelectedFruits';
import { CarryProducts } from 'components/CarryProducts';
import { SustainableRelationships } from 'components/SustainableRelationships';
import { ContactUs } from 'components/ContactUs';

const IndexPage = () => (
  <Layout>
    <SEO title="Главная" />
    <Banners />
    <Products indexPage className="pt-20 mxl:pt-16 mlg:pt-12 mmd:pt-10 msm:pt-8" />
    <CarryProducts />
    <SustainableRelationships />
    <Advantages />
    <SelectedFruits />
    <ContactUs />
  </Layout>
);

export default IndexPage;
