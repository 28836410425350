import * as React from 'react';
import cn from 'classnames';
import Slider, { Settings } from 'react-slick';
import { bannersData } from 'data/banners';
import { Banner } from './Banner';
import bottomLine from './bannersBottom.svg';
import ArrowLeftBlack from './icons/ArrowLeftBlack';
import ArrowLeftWhite from './icons/ArrowLeftWhite';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import s from './Banners.module.css';

export const Banners = () => {
  const ref = React.useRef<Slider>(null);

  const toPrev = () => ref.current && ref.current.slickPrev();

  const toNext = () => ref.current && ref.current.slickNext();

  const settings: Settings = {
    arrows: false,
    dots: true,
    dotsClass: 'border-t tr-border-yellow-2',
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul className={cn('container', s.dots)}>{dots}</ul>
      </div>
    ),
    customPaging: () => <div className={s.dot} />,
  };

  return (
    <>
      <div className="relative tr-bg-yellow-1">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider ref={ref} {...settings} className="overflow-x-hidden">
          {bannersData.map((b) => (
            <Banner key={b.title} data={b} />
          ))}
        </Slider>
        <div className={s.arrows}>
          <div className="container flex justify-between">
            <button type="button" onClick={toPrev} className={s.arrow}>
              <ArrowLeftBlack className={cn(s.arrowIcon, s.arrowBlackIcon)} />
              <ArrowLeftWhite className={cn(s.arrowIcon, s.arrowWhiteIcon)} />
            </button>
            <button type="button" onClick={toNext} className={cn(s.arrow, s.arrowNext)}>
              <ArrowLeftBlack className={cn(s.arrowIcon, s.arrowBlackIcon)} />
              <ArrowLeftWhite className={cn(s.arrowIcon, s.arrowWhiteIcon)} />
            </button>
          </div>
        </div>
      </div>
      <img src={bottomLine} alt="" className="w-full -mt-px msm:hidden" />
    </>
  );
};
